body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ap-tech {
  font-family: 'Black Ops One', sans-serif;
}

.navbar {
  font-family: "Playfair Display", sans-serif;
}

.essentials {
  font-family: "Merriweather", sans-serif;
}

.essentials-body {
  font-family: "Lobster Two", sans-serif;
}

.flash-sale-animation {
  animation-duration: 1s;
  animation-name: flash;
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;
}

@keyframes flash {
  from {
    color: black;
  }

  50% {
    color: red
  }

  to {
    color: black;
  }
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
/* a:visited {
  color: lightseagreen;
} */

/* mouse over link */
a:hover {
  color: lightgreen;
}

/* selected link */
a:active {
  color: black;
}

.overlay-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: left 0.3s ease-in-out;
}

.overlay-menu.open {
  left: 0;
}